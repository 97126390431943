interface IThemeColors {
  primary: string;
  secundary: string;
  text: string;
  inputText: string;
  boxShadow: string;
  purple: string;
  purpleLight: string;
  violet: string;
  violetLight: string;
  orange: string;
  orangeLight: string;
  yellow: string;
  yellowLight: string;
  graphite: string;
  gray: string;
  grayLight: string;
  white: string;
  magenta: string;
  blue: string;
  cyan: string;
  green: string;
  red: string;
}

export interface ITheme {
  colors: IThemeColors;
}

interface IThemeOptions {
  light: ITheme;
  dark?: ITheme;
}

const theme: IThemeOptions = {
  light: {
    colors: {
      primary: "#00BCF2",
      secundary: "#f8f8f8",
      text: "#656565",
      inputText: "#777",
      boxShadow: "rgba(0, 0, 0, 0.11)",
      purple: "#7800E1",
      purpleLight: "#965AD2",
      violet: "#785AF0",
      violetLight: "#9696FF",
      orange: "#F49639",
      orangeLight: "#FF9600",
      yellow: "#FFF100",
      yellowLight: "#FFFF00",
      graphite: "#383838",
      gray: "#9C9C9C",
      grayLight: "#9C9C9C",
      white: "#FFFFFF",
      magenta: "#FF00FF",
      blue: "#001ED2",
      cyan: "#5AFFFF",
      green: "#6CBD92",
      red: "#eb5f4a",
    },
  },
};

type TSelectedTheme = 'light' | 'dark'

export function getTheme(): ITheme {
  const currentTheme = localStorage.getItem("@App:theme") as TSelectedTheme;

  return theme[currentTheme] || theme.light;
}
